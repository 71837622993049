<script lang="ts">
	import { onMount } from 'svelte';
	export let value = '';
	export let label = '';
	export let label_for = '';
	export let required = false;
	export let disabled = false;
	export let focus = false;
	export let type = 'text';
	export let name: string | undefined = undefined;
	function typeAction(node: HTMLInputElement) {
		node.type = type;
	}
	let textField: HTMLInputElement;
	onMount(() => {
		if (focus) {
			textField.focus();
		}
	});
</script>

<div {...$$restProps} class="flex flex-col w-full space-y-1">
	{#if label}
		<label class="ml-1 text-sm xl:text-base text-zinc-200" for={label_for}>{label}</label>

		<input
			class="p-1.5 text-zinc-100 border-2 rounded-md bg-zinc-900 border-zinc-600 focus:outline-none focus:ring focus:border-blue-500 focus:bg-black disabled:cursor-not-allowed disabled:bg-zinc-800"
			{name}
			use:typeAction
			alt={label}
			id={label_for}
			{required}
			{disabled}
			bind:value
			bind:this={textField}
		/>
	{:else}
		<input
			class="p-1.5 text-zinc-100 border-2 rounded-md bg-zinc-900 border-zinc-600 focus:outline-none focus:ring focus:border-blue-500 focus:bg-black"
			{name}
			use:typeAction
			{required}
			{disabled}
			bind:value
			bind:this={textField}
		/>
	{/if}
</div>
